import axios from 'axios';
import * as types from './types';

export default {
  accountingEntrySwapExchangeValuesIfNecessary({ getters }, { data }) {
    // swap only if key currency isn't current account currency
    if (getters.keyCurrency === data.currency_code) {
      return { ...data };
    }

    // set exchange_values if not main currency but same currencies in booking
    if (data.currency_code === data.exchange_currency_code) {
      return {
        ...data,
        exchange_debit_value: data.debit_value,
        exchange_credit_value: data.credit_value,
      };
    }

    return {
      ...data,
      exchange_debit_value: data.debit_value,
      exchange_credit_value: data.credit_value,
      debit_value: data.exchange_debit_value,
      credit_value: data.exchange_credit_value,
    };
  },

  accountingEntrySetSplitExchangeValuesIfNecessary({ getters }, { data }) {
    if (
      typeof data.split_booking !== 'undefined'
      && data.split_booking.length > 0
      && data.currency_code !== data.exchange_currency_code
    ) {
      if (getters.keyCurrency === data.exchange_currency_code) {
        const splitBookings = data.split_booking.map((splitBooking) => ({
          ...splitBooking,
          exchange_debit_value: splitBooking.debit_value,
          exchange_credit_value: splitBooking.credit_value,
          debit_value: splitBooking.debit_value ? (splitBooking.debit_value * data.exchange_rate).toFixed(2) : '',
          credit_value: splitBooking.credit_value ? (splitBooking.credit_value * data.exchange_rate).toFixed(2) : '',
        }));

        return {
          ...data,
          split_booking: splitBookings,
        };
      }

      const splitBookings = data.split_booking.map((splitBooking) => ({
        ...splitBooking,
        exchange_debit_value: splitBooking.debit_value
          ? (splitBooking.debit_value * data.exchange_rate).toFixed(2)
          : '',
        exchange_credit_value: splitBooking.credit_value
          ? (splitBooking.credit_value * data.exchange_rate).toFixed(2)
          : '',
      }));

      return {
        ...data,
        split_booking: splitBookings,
      };
    }

    return data;
  },

  postAccountingEntry({ rootState, commit, dispatch }, { data }) {
    commit(types.POST_ACCOUNTING_ENTRY);

    // 1. swap debit/credit value with exchange credit/debit if its necessary
    return dispatch('accountingEntrySwapExchangeValuesIfNecessary', { data }).then(
      // 2. set split exchange values if necessary
      (response) => dispatch('accountingEntrySetSplitExchangeValuesIfNecessary', { data: response }),
    ).then((response) => {
      let filePromise;
      if (response.document instanceof File) {
        filePromise = dispatch('postAccountingFile', response.document);
      } else {
        filePromise = Promise.resolve({ id: 0 });
      }

      return filePromise.then(({ id }) => axios.post(`/accounts/${rootState.definitions.account.account.uuid}/accounting_journal_entry`, {
        ...response,
        document_id: id,
      }));
    }).then((response) => {
      commit(types.POST_ACCOUNTING_ENTRY_SUCCESS, response);
      return Promise.resolve(response);
    }).catch((error) => {
      commit(types.POST_ACCOUNTING_ENTRY_FAILURE, error);
      return Promise.reject(error);
    });
  },

  patchAccountingEntry({ rootState, commit, dispatch }, { entryId, data }) {
    commit(types.PATCH_ACCOUNTING_ENTRY);

    // 1. swap debit/credit value with exchange credit/debit if its necessary
    return dispatch('accountingEntrySwapExchangeValuesIfNecessary', { data }).then(
      // 2. set split exchange values if necessary
      (response) => dispatch('accountingEntrySetSplitExchangeValuesIfNecessary', { data: response }),
    ).then((response) => {
      if (response.document && response.document.prefilled) {
        // fast exit to keep document
        return Promise.resolve({ response, document: response.document });
      }

      if (response.document instanceof File) {
        return dispatch('postAccountingFile', response.document).then(
          (document) => Promise.resolve({ response, document }),
        );
      }

      return Promise.resolve({ response, document: { id: 0 } });
    }).then(({ response, document }) => axios.patch(
      `/accounts/${rootState.definitions.account.account.uuid}/accounting_journal_entry/${entryId}`,
      {
        ...response,
        document_id: document.id,
      },
    )).then((response) => {
      commit(types.PATCH_ACCOUNTING_ENTRY_SUCCESS, response);
      return Promise.resolve(response);
    })
      .catch((error) => {
        commit(types.PATCH_ACCOUNTING_ENTRY_FAILURE, error);
        return Promise.reject(error);
      });
  },

  postAccountingFile({ rootState, commit }, file) {
    commit(types.POST_ACCOUNTING_FILE);

    const data = new FormData();
    data.set('bookingvoucher', file);

    return axios.postForm(
      `/accounts/${rootState.definitions.account.account.uuid}/accounting_journal_entry/import`,
      data,
      {
        transformRequest: (formData) => formData,
      },
    ).then((response) => {
      commit(types.POST_ACCOUNTING_FILE_SUCCESS, response.data);
      return Promise.resolve(response.data);
    }).catch((error) => {
      commit(types.POST_ACCOUNTING_FILE_FAILURE, error);
      return Promise.reject(error);
    });
  },

  deleteAccountingEntry({ rootState, commit }, { entryId }) {
    commit(types.DELETE_ACCOUNTING_ENTRY);

    return axios.delete(`/accounts/${rootState.definitions.account.account.uuid}/accounting_journal_entry/${entryId}`).then((response) => {
      commit(types.DELETE_ACCOUNTING_ENTRY_SUCCESS, response);
      return Promise.resolve(response);
    }).catch((error) => {
      commit(types.DELETE_ACCOUNTING_ENTRY_FAILURE, error);
      return Promise.reject(error);
    });
  },

  moveAccountingEntry({ rootState, commit }, { entryId, fromAccount, toAccount }) {
    commit(types.MOVE_ACCOUNTING_ENTRY);

    return axios.patch(`/accounts/${rootState.definitions.account.account.uuid}/accounting_journal_entry/${entryId}/move`, {
      from_account: fromAccount,
      to_account: toAccount,
    }).then((response) => {
      commit(types.MOVE_ACCOUNTING_ENTRY_SUCCESS, response);
      return Promise.resolve(response);
    }).catch((error) => {
      commit(types.MOVE_ACCOUNTING_ENTRY_FAILURE, error);
      return Promise.reject(error);
    });
  },

  closeAccountingEntry({ rootState, commit }, { entryId }) {
    commit(types.CLOSE_ACCOUNTING_ENTRY);

    return axios.patch(`/accounts/${rootState.definitions.account.account.uuid}/accounting_journal_entry/${entryId}/close`).then((response) => {
      commit(types.CLOSE_ACCOUNTING_ENTRY_SUCCESS, response);
      return Promise.resolve(response);
    }).catch((error) => {
      commit(types.CLOSE_ACCOUNTING_ENTRY_FAILURE, error);
      return Promise.reject(error);
    });
  },

  cancelAccountingEntry({ rootState, commit }, { entryId }) {
    commit(types.CANCEL_ACCOUNTING_ENTRY);

    return axios.patch(`/accounts/${rootState.definitions.account.account.uuid}/accounting_journal_entry/${entryId}/cancel`).then((response) => {
      commit(types.CANCEL_ACCOUNTING_ENTRY_SUCCESS, response);
      return Promise.resolve(response);
    }).catch((error) => {
      commit(types.CANCEL_ACCOUNTING_ENTRY_FAILURE, error);
      return Promise.reject(error);
    });
  },

  syncAccountingEntry({ rootState, commit }, { entryId, forceSync }) {
    commit(types.SYNC_ACCOUNTING_ENTRY);

    return axios.patch(`/accounts/${rootState.definitions.account.account.uuid}/accounting_journal_entry/${entryId}/sync`, { forceSync }).then((response) => {
      commit(types.SYNC_ACCOUNTING_ENTRY_SUCCESS, response);
      return Promise.resolve(response);
    }).catch((error) => {
      commit(types.SYNC_ACCOUNTING_ENTRY_FAILURE, error);
      return Promise.reject(error);
    });
  },

  bulkCloseAccountingEntries({ rootState, commit }, { ids }) {
    commit(types.BULK_CLOSE_ACCOUNTING_ENTRIES);

    return axios.patch(`/accounts/${rootState.definitions.account.account.uuid}/accounting_journal_entry/close`, ids).then((response) => {
      commit(types.BULK_CLOSE_ACCOUNTING_ENTRIES_SUCCESS, response);
      return Promise.resolve(response);
    }).catch((error) => {
      commit(types.BULK_CLOSE_ACCOUNTING_ENTRIES_FAILURE, error);
      return Promise.reject(error);
    });
  },

  changeAccountingYear({ commit }, id) {
    commit(types.CHANGE_ACCOUNTING_YEAR, id);
  },

  fetchAccountingDocument({ rootState, commit }, { documentId, abortController }) {
    const controller = abortController || new AbortController();

    commit(types.FETCH_ACCOUNTING_DOCUMENT);

    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/pagination/accounting/document/${documentId}`, {
      signal: controller.signal,
    }).then((response) => {
      commit(types.FETCH_ACCOUNTING_DOCUMENT_SUCCESS, response);
      return Promise.resolve(response);
    }).catch((error) => {
      if (axios.isCancel(error)) {
        commit(types.FETCH_ACCOUNTING_DOCUMENT_CANCEL);
        return Promise.resolve();
      }
      commit(types.FETCH_ACCOUNTING_DOCUMENT_FAILURE, error);
      return Promise.reject(error);
    });
  },

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  async fetchAccountReady({ commit, dispatch }, { yearId }) {
    commit(types.FETCH_ACCOUNT_READY_PENDING);
    try {
      await Promise.all([
        dispatch('fetchAccountingReady'),
        dispatch('fetchAccountingYears', yearId),
      ]);

      commit(types.FETCH_ACCOUNT_READY_FULFILLED);
    } catch (error) {
      commit(types.FETCH_ACCOUNT_READY_ERROR, error);
    }
  },
  async fetchAccountingReady({ rootState, commit }) {
    commit(types.FETCH_ACCOUNTING_READY_PENDING);
    try {
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/accounting_ready`);
      commit(types.FETCH_ACCOUNTING_READY_FULFILLED, response);
      return true;
    } catch (error) {
      commit(types.FETCH_ACCOUNTING_READY_ERROR);
      return false;
    }
  },
  async fetchAccountingYears({ rootState, commit }, yearId) {
    commit(types.FETCH_ACCOUNTING_YEARS_PENDING);
    try {
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/accounting_years`);
      commit(types.FETCH_ACCOUNTING_YEARS_FULFILLED, { ...response, yearId });
      return true;
    } catch (error) {
      commit(types.FETCH_ACCOUNTING_YEARS_ERROR);
      return false;
    }
  },
};
