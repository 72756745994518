import axios from 'axios';
import * as types from './types';

const actions = {
  async fetchNotifications({ commit }) {
    commit(types.FETCH_NOTIFICATIONS_PENDING);
    const notifications = await axios.get('/notificationcenter/notifications');
    commit(types.FETCH_NOTIFICATIONS, notifications);
    return Promise.resolve(notifications);
  },
  async getNotificationById({ commit, state, dispatch }, id) {
    commit(types.FETCH_NOTIFICATION_PENDING);
    if (state.notifications.length < 1) {
      await dispatch('fetchNotifications');
    }
    const notification = state.notifications.find(
      (notificationValue) => parseInt(notificationValue.id, 10) === parseInt(id, 10),
    );
    commit(types.FETCH_NOTIFICATION, { data: JSON.parse(JSON.stringify(notification)) });
    return Promise.resolve(notification);
  },
  async storeNotification({ commit }, notificationObject) {
    commit(types.FETCH_NOTIFICATION_PENDING);
    notificationObject.selectedUserGroups = notificationObject.selected_user_groups;
    notificationObject.notificationType = { value: notificationObject.type, text: notificationObject.type };
    notificationObject.title = {
      de: '',
      en: '',
      fr: '',
      it: '',
    };
    notificationObject.text = {
      de: '',
      en: '',
      fr: '',
      it: '',
    };
    notificationObject.notification_texts.forEach(
      (textElement) => {
        notificationObject.title[textElement.locale] = textElement.title;
        notificationObject.text[textElement.locale] = textElement.text;
      },
    );
    if (notificationObject.show_from) {
      notificationObject.showFrom = notificationObject.show_from;
    }
    if (notificationObject.show_until) {
      notificationObject.showUntil = notificationObject.show_until;
    }
    let notification;
    if (!notificationObject.id) {
      notification = await axios.post('/notificationcenter/notifications', notificationObject);
    } else {
      notification = await axios.put(`/notificationcenter/notifications/${notificationObject.id}`, notificationObject);
    }
    commit(types.FETCH_NOTIFICATION, { notification });
    return Promise.resolve(notification);
  },
  async deleteNotification({ commit, dispatch }, notificationId) {
    commit(types.FETCH_NOTIFICATIONS_PENDING);
    const notification = await axios.delete(`/notificationcenter/notifications/${notificationId}`);
    if (notification) {
      await dispatch('fetchNotifications');
    }
    return Promise.resolve(notificationId);
  },

  async fetchNotificationtTypes({ state, commit }) {
    if (state.notificationTypes.length > 0) {
      return Promise.resolve(state.notificationTypes);
    }
    commit(types.FETCH_NOTIFICATION_TYPES_PENDING);
    // TODO: Get proper array
    const notificationTypes = await axios.get('/notificationcenter/notificationtypes');
    const notificationTypesArray = Object.keys(notificationTypes.data).map(
      (key) => ({ key, value: notificationTypes.data[key] }),
    );
    commit(types.FETCH_NOTIFICATION_TYPES, { data: notificationTypesArray });
    return Promise.resolve(state.notificationTypes);
  },
};

export default actions;
