import axios from 'axios';
import Vue from 'vue';
import * as types from './types';

const actions = {
  async fetchDefinitions({ rootState, commit }) {
    commit(types.INITIAL_LOAD_PENDING, true);
    try {
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/definitions`);
      commit(types.INITIAL_LOAD_FULFILLED, response);
      commit(types.INITIAL_LOAD_PENDING, false);
      return Promise.resolve(response.data);
    } catch (error) {
      console.error(`[INITIAL_LOAD_ERROR] ${error}`);
      commit(types.INITIAL_LOAD_ERROR);
      return Promise.reject(error);
    }
  },

  async fetchBankAccounts({ rootState, commit }) {
    try {
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/definitions/bankaccounts`);
      commit(types.FETCH_BANK_ACCOUNTS, response);
      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async fetchModuleServices({ rootState, commit }) {
    try {
      const data = await axios.get(`/settings/accounts/${rootState.definitions.account.account.uuid}/services`);
      commit(types.FETCH_MODULE_SERVICES_SUCCESS, data);
      return Promise.resolve(data.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async storeModuleAbacus({ rootState, commit }, { form }) {
    try {
      commit(types.STORE_SETTINGS_PENDING, true);
      const data = await axios.post(`/settings/accounts/${rootState.definitions.account.account.uuid}/services/abacus`, form);
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async storeModuleAbaNet({ rootState, commit }, { form }) {
    try {
      commit(types.STORE_SETTINGS_PENDING, true);
      const data = await axios.post(`/settings/${rootState.definitions.account.account.uuid}/services/abanet/connect`, form);
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },

  async storeModuleDeepBox({ rootState, commit }, { form }) {
    try {
      commit(types.STORE_DEEPBOX_SETTINGS_PENDING);
      const data = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/settings/services/deepbox`, form);
      commit(types.STORE_DEEPBOX_SETTINGS_FULFILLED);
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.STORE_DEEPBOX_SETTINGS_ERROR);
      return Promise.reject(error);
    }
  },
  async fetchModuleService({ rootState }, { key }) {
    try {
      const data = await axios.get(`/settings/accounts/${rootState.definitions.account.account.uuid}/services/${key}`);
      return Promise.resolve(data.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async deleteModuleService({ rootState, commit }, { key }) {
    try {
      commit(types.STORE_SETTINGS_PENDING, true);
      const data = await axios.delete(`/settings/accounts/${rootState.definitions.account.account.uuid}/services/${key}`);
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async confirmAccount({ rootState, commit, dispatch }, accountValue) {
    commit(types.CONFIRM_PENDING, true);
    try {
      const response = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/confirm`, accountValue);
      window.location.href = '/dashboard';
      commit(types.CONFIRM_PENDING, false);
      return Promise.resolve(response);
    } catch (error) {
      commit(types.CONFIRM_PENDING, false);
      dispatch('messages/showMessage', {
        key: 'error_save_settings',
        color: 'error',
      }, { root: true });
      return Promise.reject(error);
    }
  },
  async storeRegistration({ rootState, commit, dispatch }, accountValue) {
    commit(types.REGISTRATION_PENDING, true);
    try {
      const url = `/accounts/${rootState.definitions.account.account.uuid}/wizard`;
      const response = await axios.post(url, accountValue);
      commit(types.REGISTRATION_PENDING, false);
      return Promise.resolve(response);
    } catch (error) {
      commit(types.REGISTRATION_PENDING, false);
      dispatch('messages/showMessage', {
        text: error.data.message,
        color: 'error',
      }, { root: true });
      return Promise.reject(error);
    }
  },
  async fetchAgreements({ rootState, commit, dispatch }) {
    commit(types.FETCH_AGREEMENTS_PENDING);
    try {
      const url = `/accounts/${rootState.definitions.account.account.uuid}/agreements`;
      const response = await axios.get(url);
      commit(types.FETCH_AGREEMENTS_SUCCESSFUL);
      return Promise.resolve(response.data);
    } catch (error) {
      commit(types.FETCH_AGREEMENTS_ERROR);
      dispatch('messages/showMessage', {
        text: error.data.message,
        color: 'error',
      }, { root: true });
      return Promise.reject(error);
    }
  },
  async confirmAgreements({ rootState, commit, dispatch }, data) {
    commit(types.CONFIRM_AGREEMENTS_PENDING);
    try {
      const url = `/accounts/${rootState.definitions.account.account.uuid}/agreements`;
      await axios.post(url, data);
      if (!rootState.definitions.account.account.has_all_agb_accepted) {
        commit(types.CONFIRM_AGREEMENTS_ERROR);
        return Promise.reject(new Error('CONFIRM_AGREEMENTS_ERROR'));
      }
      commit(types.CONFIRM_AGREEMENTS_SUCCESSFUL);
      return Promise.resolve();
    } catch (error) {
      commit(types.CONFIRM_AGREEMENTS_ERROR);
      dispatch('messages/showMessage', {
        text: error.data.message,
        color: 'error',
      }, { root: true });
      return Promise.reject(error);
    }
  },
  async storeSchoolAccount({ rootState, commit, dispatch }) {
    commit(types.REGISTRATION_PENDING, true);
    try {
      const url = `/accounts/${rootState.definitions.account.account.uuid}/copy`;
      const response = await axios.post(url);
      commit(types.REGISTRATION_PENDING, false);
      return Promise.resolve(response);
    } catch (error) {
      commit(types.REGISTRATION_PENDING, false);
      dispatch('messages/showMessage', {
        key: 'error_save_settings',
        color: 'error',
      }, { root: true });
      return Promise.reject(error);
    }
  },
  storeLogo({ rootState }, file) {
    const data = new FormData();
    data.set('logo', file);

    return axios.postForm(
      `/accounts/${rootState.definitions.account.account.uuid}/logo`,
      data,
      {
        transformRequest: (formData) => formData,
      },
    );
  },
  deleteLogo({ rootState }) {
    return axios.delete(
      `/accounts/${rootState.definitions.account.account.uuid}/logo`,
    );
  },
  loginState({ commit }, isLoggedIn) {
    commit(types.LOGIN_STATE, isLoggedIn);
    if (Vue.$tokenRefreshInterval) {
      clearInterval(Vue.$tokenRefreshInterval);
    }
  },
  storeTokenTime(context, tokenTime) {
    localStorage.setItem(`${document.location.host}_tokenCreateTime`, tokenTime);
  },
  fetchTokenTime() {
    const tokenTime = localStorage.getItem(`${document.location.host}_tokenCreateTime`);
    if (!tokenTime) {
      return null;
    }
    return new Date(tokenTime);
  },
  deleteTokenTime() {
    localStorage.removeItem(`${document.location.host}_tokenCreateTime`);
  },
  sessionKeepAlive({ rootState }) {
    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/session/keep_alive`);
  },
  async patternPreview({ rootState }, { patternObject }) {
    const data = await axios.post(`/settings/accounts/${rootState.definitions.account.account.uuid}/pattern`, patternObject);
    return data.data.previewNumber;
  },
  async fetchReceiptNumber({ rootState, commit }) {
    commit(types.FETCH_SETTINGS_PENDING, true);
    const data = await axios.get(`/settings/accounts/${rootState.definitions.account.account.uuid}/receipts`);
    commit(types.FETCH_SETTINGS_PENDING, false);
    return data.data;
  },
  async storeReceiptNumber({ rootState, commit }, { patternObject }) {
    commit(types.STORE_SETTINGS_PENDING, true);
    const data = await axios.patch(`/settings/accounts/${rootState.definitions.account.account.uuid}/receipts`, patternObject);
    commit(types.STORE_SETTINGS_PENDING, false);
    return data.data;
  },

  async fetchExchangeRates({ rootState, commit, dispatch }) {
    commit(types.FETCH_SETTINGS_PENDING, true);
    const [exchangeRates, currenciesSync] = await Promise.all([
      axios.get(`/settings/accounts/${rootState.definitions.account.account.uuid}/acc_exchange_rates`),
      dispatch('fetchCurrenciesSync'),
    ]);
    const payload = {
      exchangeRates: exchangeRates.data,
      currenciesSync,
    };
    commit(types.FETCH_EXCHANGE_RATES, payload);
    commit(types.FETCH_SETTINGS_PENDING, false);
    return exchangeRates.data;
  },
  async fetchExchangeRate({ rootState, commit }, { id }) {
    try {
      commit(types.FETCH_SETTINGS_PENDING, true);
      const data = await axios.get(`/settings/accounts/${rootState.definitions.account.account.uuid}/acc_exchange_rates/${id}`);
      commit(types.FETCH_SETTINGS_PENDING, false);
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.FETCH_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },

  async fetchCurrenciesSync({ rootState, commit }) {
    try {
      commit(types.FETCH_SETTINGS_PENDING, true);
      const data = await axios.get(`/settings/accounts/${rootState.definitions.account.account.uuid}/acc_exchange_rate/sync`);
      commit(types.FETCH_SETTINGS_PENDING, false);
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.FETCH_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },

  async fetchKeyFigures({ rootState, commit }) {
    try {
      commit(types.FETCH_SETTINGS_PENDING, true);
      const data = await axios.get(`/settings/accounts/${rootState.definitions.account.account.uuid}/acc_key_figures`);
      commit(types.FETCH_SETTINGS_PENDING, false);
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.FETCH_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },

  async fetchGateways({ rootState, commit }) {
    try {
      commit(types.FETCH_SETTINGS_PENDING, true);
      const data = await axios.get(`/settings/accounts/${rootState.definitions.account.account.uuid}/gateways`);
      commit(types.FETCH_SETTINGS_PENDING, false);
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.FETCH_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async storeGateway({ rootState, commit }, { payload }) {
    try {
      commit(types.STORE_SETTINGS_PENDING, true);
      let data;
      if (payload.id) {
        data = await axios.patch(`/settings/accounts/${rootState.definitions.account.account.uuid}/gateways/${payload.id}`, payload);
      } else {
        data = await axios.post(`/settings/accounts/${rootState.definitions.account.account.uuid}/gateways`, payload);
      }
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },
  async archiveGateway({ rootState, commit }, { id }) {
    try {
      commit(types.STORE_SETTINGS_PENDING, true);
      const data = await axios.patch(`/settings/accounts/${rootState.definitions.account.account.uuid}/gateways/${id}/archive`);
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.STORE_SETTINGS_PENDING, false);
      return Promise.reject(error);
    }
  },

  async fetchModuleDeepBoxInitial({ rootState, commit }) {
    commit(types.FETCH_DEEPBOX_INITIAL_PENDING);
    try {
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/settings/services/deepbox`);
      commit(types.FETCH_DEEPBOX_INITIAL_FULFILLED, response);
      return Promise.resolve(true);
    } catch (error) {
      commit(types.FETCH_DEEPBOX_INITIAL_ERROR);
      return Promise.reject(error);
    }
  },
  setNumberRangeSettings({ commit }, data) {
    commit(types.SET_NUMBER_RANGE_SETTINGS, data);
  },
};

export default actions;
