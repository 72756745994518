const PassThrough = () => import('@/views/PassThrough.vue');

export default {
  path: '/reports',
  name: 'more',
  component: PassThrough,
  redirect: { name: 'more-default' },
  children: [
    {
      path: '',
      name: 'more-default',
      redirect: '/v3/more/charts-and-reports/invoices',
    },
    {
      path: 'charts_and_reports/:reportType',
      name: 'ChartsAndReports',
      redirect: (to) => `/v3/more/charts-and-reports/${to.params.reportType.replace('_', '-')}`,
    },
  ],
};
