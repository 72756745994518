import type { RouteConfig } from 'vue-router/types/router';

const merchantCategoryCodeRoutes: RouteConfig[] = [{
  path: 'merchant-category-codes',
  name: 'merchant-category-codes',
  redirect: '/v3/settings/accounting/merchant-category-codes',
}, {
  path: 'merchant-category-code/:uuid?',
  name: 'merchant-category-code',
  redirect: '/v3/settings/accounting/merchant-category-codes/:uuid?',
}];

export default merchantCategoryCodeRoutes;
