import type { RouteConfig } from 'vue-router/types/router';

const PassThrough = () => import('@/views/PassThrough.vue');

export const apiTokensRoutes: Array<RouteConfig> = [{
  path: 'api-tokens',
  component: PassThrough,
  children: [
    {
      path: '',
      name: 'api-tokens',
      redirect: { path: '/v3/settings/company/api-tokens' },
      meta: {
        title: 'api_tokens',
      },
    },
    {
      path: 'create',
      name: 'api-token-create',
      redirect: { path: '/v3/settings/company/api-tokens/api-token' },
      meta: {
        title: 'api_token_create',
      },
    },
    {
      path: 'edit/:uuid',
      name: 'api-token-edit',
      redirect: { path: '/v3/settings/company/api-tokens/:uuid/api-token' },
      meta: {
        title: 'api_token_edit',
      },
    },
  ],
}];
