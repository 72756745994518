export default {
  isLoggedIn: true,
  exchangeRates: [],
  autoExchangeRates: true,
  services: {},
  definitionsPending: false,
  registrationPending: false,
  confirmPending: false,
  storeSettingsPending: false,
  fetchSettingsPending: false,
  storeDeepBoxSettingsPending: false,
  deepBoxFetchInitialPending: false,
  deepBoxService: {
    service: false,
    mappings: [],
    folders: [],
  },
  numberRanges: {
    address: {},
  },
  fetchAgreementsPending: false,
  confirmAgreementsPending: false,
};
