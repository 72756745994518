export const POST_ACCOUNTING_ENTRY = 'POST_ACCOUNTING_ENTRY';
export const POST_ACCOUNTING_ENTRY_SUCCESS = 'POST_ACCOUNTING_ENTRY_SUCCESS';
export const POST_ACCOUNTING_ENTRY_FAILURE = 'POST_ACCOUNTING_ENTRY_FAILURE';

export const PATCH_ACCOUNTING_ENTRY = 'PATCH_ACCOUNTING_ENTRY';
export const PATCH_ACCOUNTING_ENTRY_SUCCESS = 'PATCH_ACCOUNTING_ENTRY_SUCCESS';
export const PATCH_ACCOUNTING_ENTRY_FAILURE = 'PATCH_ACCOUNTING_ENTRY_FAILURE';

export const POST_ACCOUNTING_FILE = 'POST_ACCOUNTING_FILE';
export const POST_ACCOUNTING_FILE_SUCCESS = 'POST_ACCOUNTING_FILE_SUCCESS';
export const POST_ACCOUNTING_FILE_FAILURE = 'POST_ACCOUNTING_FILE_FAILURE';

export const DELETE_ACCOUNTING_ENTRY = 'DELETE_ACCOUNTING_ENTRY';
export const DELETE_ACCOUNTING_ENTRY_SUCCESS = 'DELETE_ACCOUNTING_ENTRY_SUCCESS';
export const DELETE_ACCOUNTING_ENTRY_FAILURE = 'DELETE_ACCOUNTING_ENTRY_FAILURE';

export const MOVE_ACCOUNTING_ENTRY = 'MOVE_ACCOUNTING_ENTRY';
export const MOVE_ACCOUNTING_ENTRY_SUCCESS = 'MOVE_ACCOUNTING_ENTRY_SUCCESS';
export const MOVE_ACCOUNTING_ENTRY_FAILURE = 'MOVE_ACCOUNTING_ENTRY_FAILURE';

export const CLOSE_ACCOUNTING_ENTRY = 'CLOSE_ACCOUNTING_ENTRY';
export const CLOSE_ACCOUNTING_ENTRY_SUCCESS = 'CLOSE_ACCOUNTING_ENTRY_SUCCESS';
export const CLOSE_ACCOUNTING_ENTRY_FAILURE = 'CLOSE_ACCOUNTING_ENTRY_FAILURE';

export const CANCEL_ACCOUNTING_ENTRY = 'CANCEL_ACCOUNTING_ENTRY';
export const CANCEL_ACCOUNTING_ENTRY_SUCCESS = 'CANCEL_ACCOUNTING_ENTRY_SUCCESS';
export const CANCEL_ACCOUNTING_ENTRY_FAILURE = 'CANCEL_ACCOUNTING_ENTRY_FAILURE';

export const SYNC_ACCOUNTING_ENTRY = 'SYNC_ACCOUNTING_ENTRY';
export const SYNC_ACCOUNTING_ENTRY_SUCCESS = 'SYNC_ACCOUNTING_ENTRY_SUCCESS';
export const SYNC_ACCOUNTING_ENTRY_FAILURE = 'SYNC_ACCOUNTING_ENTRY_FAILURE';

export const BULK_CLOSE_ACCOUNTING_ENTRIES = 'BULK_CLOSE_ACCOUNTING_ENTRIES';
export const BULK_CLOSE_ACCOUNTING_ENTRIES_SUCCESS = 'BULK_CLOSE_ACCOUNTING_ENTRIES_SUCCESS';
export const BULK_CLOSE_ACCOUNTING_ENTRIES_FAILURE = 'BULK_CLOSE_ACCOUNTING_ENTRIES_FAILURE';

export const FETCH_ACCOUNTING_DOCUMENT = 'FETCH_ACCOUNTING_DOCUMENT';
export const FETCH_ACCOUNTING_DOCUMENT_SUCCESS = 'FETCH_ACCOUNTING_DOCUMENT_SUCCESS';
export const FETCH_ACCOUNTING_DOCUMENT_FAILURE = 'FETCH_ACCOUNTING_DOCUMENT_FAILURE';
export const FETCH_ACCOUNTING_DOCUMENT_CANCEL = 'FETCH_ACCOUNTING_DOCUMENT_CANCEL';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export const FETCH_ACCOUNT_READY_PENDING = 'FETCH_ACCOUNT_READY_PENDING';
export const FETCH_ACCOUNT_READY_FULFILLED = 'FETCH_ACCOUNT_READY_FULFILLED';
export const FETCH_ACCOUNT_READY_ERROR = 'FETCH_ACCOUNT_READY_ERROR';

export const FETCH_ACCOUNTING_READY_PENDING = 'FETCH_ACCOUNTING_READY_PENDING';
export const FETCH_ACCOUNTING_READY_FULFILLED = 'FETCH_ACCOUNTING_READY_FULFILLED';
export const FETCH_ACCOUNTING_READY_ERROR = 'FETCH_ACCOUNTING_READY_ERROR';

export const FETCH_ACCOUNTING_YEARS_PENDING = 'FETCH_ACCOUNTING_YEARS_PENDING';
export const FETCH_ACCOUNTING_YEARS_FULFILLED = 'FETCH_ACCOUNTING_YEARS_FULFILLED';
export const FETCH_ACCOUNTING_YEARS_ERROR = 'FETCH_ACCOUNTING_YEARS_ERROR';

export const CHANGE_ACCOUNTING_YEAR = 'CHANGE_ACCOUNTING_YEAR';
