export const FETCH_BANK_ACCOUNTS = 'FETCH_BANK_ACCOUNTS';
export const FETCH_EXCHANGE_RATES = 'FETCH_EXCHANGE_RATES';

export const STORE_SETTINGS_PENDING = 'STORE_SETTINGS_PENDING';

export const FETCH_SETTINGS_PENDING = 'FETCH_SETTINGS_PENDING';

export const INITIAL_LOAD_PENDING = 'INITIAL_LOAD_PENDING';
export const INITIAL_LOAD_FULFILLED = 'INITIAL_LOAD_FULFILLED';
export const INITIAL_LOAD_ERROR = 'INITIAL_LOAD_ERROR';
export const REGISTRATION_PENDING = 'REGISTRATION_PENDING';
export const CONFIRM_PENDING = 'CONFIRM_PENDING';
export const LOGIN_STATE = 'LOGIN_STATE';

export const FETCH_MODULE_SERVICES_SUCCESS = 'FETCH_MODULE_SERVICES_SUCCESS';

// DeepBox
export const FETCH_DEEPBOX_INITIAL_PENDING = 'FETCH_DEEPBOX_INITIAL_PENDING';
export const FETCH_DEEPBOX_INITIAL_FULFILLED = 'FETCH_DEEPBOX_INITIAL_FULFILLED';
export const FETCH_DEEPBOX_INITIAL_ERROR = 'FETCH_DEEPBOX_INITIAL_ERROR';

export const STORE_DEEPBOX_SETTINGS_PENDING = 'STORE_DEEPBOX_SETTINGS_PENDING';
export const STORE_DEEPBOX_SETTINGS_FULFILLED = 'STORE_DEEPBOX_SETTINGS_FULFILLED';
export const STORE_DEEPBOX_SETTINGS_ERROR = 'STORE_DEEPBOX_SETTINGS_ERROR';

export const SET_NUMBER_RANGE_SETTINGS = 'SET_NUMBER_RANGE_SETTINGS';

export const FETCH_AGREEMENTS_PENDING = 'FETCH_AGREEMENTS_PENDING';
export const FETCH_AGREEMENTS_SUCCESSFUL = 'FETCH_AGREEMENTS_SUCCESSFUL';
export const FETCH_AGREEMENTS_ERROR = 'FETCH_AGREEMENTS_ERROR';

export const CONFIRM_AGREEMENTS_PENDING = 'CONFIRM_AGREEMENTS_PENDING';
export const CONFIRM_AGREEMENTS_SUCCESSFUL = 'CONFIRM_AGREEMENTS_SUCCESSFUL';
export const CONFIRM_AGREEMENTS_ERROR = 'CONFIRM_AGREEMENTS_ERROR';
