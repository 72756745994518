import * as types from './types';

const mutations = {
  [types.INITIAL_LOAD_PENDING](state, isPending) {
    state.definitionsPending = isPending;
  },
  [types.INITIAL_LOAD_FULFILLED](state, { data }) {
    state.user = data.user;
  },
  [types.INITIAL_LOAD_ERROR](state) {
    state.definitionsPending = false;
  },

  [types.FETCH_BANK_ACCOUNTS](state, { data }) {
    state.bankAccounts = data;
  },

  [types.FETCH_EXCHANGE_RATES](state, { exchangeRates, currenciesSync }) {
    state.autoExchangeRates = currenciesSync.auto_exchange_rates;
    const grouped = [];
    const rateIndex = (currencyCode, exchangeCurrencyCode) => grouped.findIndex((item) => {
      const sameCurrencyCode = item.currency_code === currencyCode;
      const sameExchangeCurrenyCode = item.exchange_currency_code === exchangeCurrencyCode;
      return sameCurrencyCode && sameExchangeCurrenyCode;
    });
    exchangeRates
      .sort((a, b) => new Date(b.valid_from) - new Date(a.valid_from))
      .map((rate) => {
        let index = rateIndex(rate.currency_code, rate.exchange_currency_code);
        if (index < 0) {
          grouped.push({
            currency_code: rate.currency_code,
            exchange_currency_code: rate.exchange_currency_code,
            rates: [],
          });
          index = rateIndex(rate.currency_code, rate.exchange_currency_code);
        }
        if (index >= 0) {
          grouped[index].rates.push({
            id: rate.id,
            isManual: !!rate.account_id,
            rate: rate.rate,
            validFrom: rate.valid_from,
          });
        }
        return rate;
      });
    state.exchangeRates = grouped;
  },
  [types.REGISTRATION_PENDING](state, isPending) {
    state.registrationPending = isPending;
  },
  [types.CONFIRM_PENDING](state, isPending) {
    state.confirmPending = isPending;
  },
  [types.LOGIN_STATE](state, isLoggedIn) {
    state.isLoggedIn = isLoggedIn;
  },
  [types.STORE_SETTINGS_PENDING](state, isPending) {
    state.storeSettingsPending = isPending;
  },
  [types.FETCH_SETTINGS_PENDING](state, isPending) {
    state.fetchSettingsPending = isPending;
  },

  [types.FETCH_MODULE_SERVICES_SUCCESS](state, { data }) {
    state.services = data;
  },
  // REMOVE_ACC_BASE_ACCOUNTS

  // DEEPBOX FETCH INITIAL
  [types.FETCH_DEEPBOX_INITIAL_PENDING](state) {
    state.deepBoxFetchInitialPending = true;
  },
  [types.FETCH_DEEPBOX_INITIAL_FULFILLED](state, { data }) {
    state.deepBoxFetchInitialPending = false;
    state.deepBoxService = data;
  },
  [types.FETCH_DEEPBOX_INITIAL_ERROR](state) {
    state.deepBoxFetchInitialPending = false;
  },

  // DEEPBOX STORE SETTINGS
  [types.STORE_DEEPBOX_SETTINGS_PENDING](state) {
    state.storeDeepBoxSettingsPending = true;
  },
  [types.STORE_DEEPBOX_SETTINGS_FULFILLED](state) {
    state.storeDeepBoxSettingsPending = false;
  },
  [types.STORE_DEEPBOX_SETTINGS_ERROR](state) {
    state.storeDeepBoxSettingsPending = false;
  },

  [types.SET_NUMBER_RANGE_SETTINGS](state, { type, is_active: isActive }) {
    state.numberRanges[type].is_active = isActive;
  },
  // FETCH_AGREEMENTS
  [types.FETCH_AGREEMENTS_PENDING](state) {
    state.fetchAgreementsPending = true;
  },
  [types.FETCH_AGREEMENTS_SUCCESSFUL](state) {
    state.fetchAgreementsPending = false;
  },
  [types.FETCH_AGREEMENTS_ERROR](state) {
    state.fetchAgreementsPending = false;
  },
  // CONFIRM_AGREEMENTS
  [types.CONFIRM_AGREEMENTS_PENDING](state) {
    state.confirmAgreementsPending = true;
  },
  [types.CONFIRM_AGREEMENTS_SUCCESSFUL](state) {
    state.confirmAgreementsPending = false;
  },
  [types.CONFIRM_AGREEMENTS_ERROR](state) {
    state.confirmAgreementsPending = false;
  },
};
export default mutations;
