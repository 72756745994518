/* global moment */
import axios from 'axios';

const FETCH_ABABANK_BANKS_PENDING = 'FETCH_ABABANK_BANKS_PENDING';
const FETCH_ABABANK_BANKS_FULFILLED = 'FETCH_ABABANK_BANKS_FULFILLED';
const FETCH_ABABANK_BANKS_ERROR = 'FETCH_ABABANK_BANKS_ERROR';

const STORE_ABABANK_PENDING = 'STORE_ABABANK_PENDING';
const STORE_ABABANK_FULFILLED = 'STORE_ABABANK_FULFILLED';
const STORE_ABABANK_ERROR = 'STORE_ABABANK_ERROR';

const STORE_POSTFINANCE_ACCOUNT_PENDING = 'STORE_POSTFINANCE_ACCOUNT_PENDING';
const STORE_POSTFINANCE_ACCOUNT_FULFILLED = 'STORE_POSTFINANCE_ACCOUNT_FULFILLED';
const STORE_POSTFINANCE_ACCOUNT_ERROR = 'STORE_POSTFINANCE_ACCOUNT_ERROR';

const FETCH_POSTFINANCE_ACCOUNTS_PENDING = 'FETCH_POSTFINANCE_ACCOUNTS_PENDING';
const FETCH_POSTFINANCE_ACCOUNTS_FULFILLED = 'FETCH_POSTFINANCE_ACCOUNTS_FULFILLED';
const FETCH_POSTFINANCE_ACCOUNTS_ERROR = 'FETCH_POSTFINANCE_ACCOUNTS_ERROR';

const FETCH_POSTFINANCE_DOCUMENTS_PENDING = 'FETCH_POSTFINANCE_DOCUMENTS_PENDING';
const FETCH_POSTFINANCE_DOCUMENTS_FULFILLED = 'FETCH_POSTFINANCE_DOCUMENTS_FULFILLED';
const FETCH_POSTFINANCE_DOCUMENTS_ERROR = 'FETCH_POSTFINANCE_DOCUMENTS_ERROR';

const FETCH_ABABANK_STATEMENTS_PENDING = 'FETCH_ABABANK_STATEMENTS_PENDING';
const FETCH_ABABANK_STATEMENTS_FULFILLED = 'FETCH_ABABANK_STATEMENTS_FULFILLED';
const FETCH_ABABANK_STATEMENTS_ERROR = 'FETCH_ABABANK_STATEMENTS_ERROR';

const PUSH_ABABANK_STATEMENTS_PENDING = 'PUSH_ABABANK_STATEMENTS_PENDING';
const PUSH_ABABANK_STATEMENTS_FULFILLED = 'PUSH_ABABANK_STATEMENTS_FULFILLED';
const PUSH_ABABANK_STATEMENTS_ERROR = 'PUSH_ABABANK_STATEMENTS_ERROR';

const SYNC_ABABANK_PENDING = 'SYNC_ABABANK_PENDING';
const SYNC_ABABANK_FULFILLED = 'SYNC_ABABANK_FULFILLED';
const SYNC_ABABANK_ERROR = 'SYNC_ABABANK_ERROR';

const REMOVE_ABABANK_PENDING = 'REMOVE_ABABANK_PENDING';
const REMOVE_ABABANK_FULFILLED = 'REMOVE_ABABANK_FULFILLED';
const REMOVE_ABABANK_ERROR = 'REMOVE_ABABANK_ERROR';

const CONNECT_ABABANK_PENDING = 'CONNECT_ABABANK_PENDING';
const CONNECT_ABABANK_FULFILLED = 'CONNECT_ABABANK_FULFILLED';
const CONNECT_ABABANK_ERROR = 'CONNECT_ABABANK_ERROR';

const ONBOARDING_ABABANK_PENDING = 'ONBOARDING_ABABANK_PENDING';
const ONBOARDING_ABABANK_FULFILLED = 'ONBOARDING_ABABANK_FULFILLED';
const ONBOARDING_ABABANK_ERROR = 'ONBOARDING_ABABANK_ERROR';

const CALLBACK_ABABANK_PENDING = 'CALLBACK_ABABANK_PENDING';
const CALLBACK_ABABANK_FULFILLED = 'CALLBACK_ABABANK_FULFILLED';
const CALLBACK_ABABANK_ERROR = 'CALLBACK_ABABANK_ERROR';

const initialState = {
  fetchAbabankBanksPending: false,
  ababankBanks: [],
  storeAbabankPending: false,
  storePostfinanceAccountPending: false,
  fetchPostfinanceAccountsPending: false,
  postfinanceAccounts: [],
  fetchPostfinanceDocumentsPending: false,
  fetchAbabankStatementsPending: false,
  pushAbabankStatementsPending: false,
  syncAbabankPending: false,
  syncAbabankPendingIds: [],
  removeAbabankPending: false,
  connectAbabankPending: false,
  onboardingAbaBankPending: false,
  callbackAbaBankPending: false,
};

const mutations = {
  // FETCH_ABABANK_BANKS
  [FETCH_ABABANK_BANKS_PENDING](state) {
    state.fetchAbabankBanksPending = true;
  },
  [FETCH_ABABANK_BANKS_FULFILLED](state, { data }) {
    state.ababankBanks = data;
    state.fetchAbabankBanksPending = false;
  },
  [FETCH_ABABANK_BANKS_ERROR](state) {
    state.fetchAbabankBanksPending = false;
  },
  // STORE_ABABANK
  [STORE_ABABANK_PENDING](state) {
    state.storeAbabanKPending = true;
  },
  [STORE_ABABANK_FULFILLED](state) {
    state.storeAbabanKPending = false;
  },
  [STORE_ABABANK_ERROR](state) {
    state.storeAbabanKPending = false;
  },
  // STORE_POSTFINANCE_ACCOUNT
  [STORE_POSTFINANCE_ACCOUNT_PENDING](state) {
    state.storePostfinanceAccountPending = true;
  },
  [STORE_POSTFINANCE_ACCOUNT_FULFILLED](state) {
    state.storePostfinanceAccountPending = false;
  },
  [STORE_POSTFINANCE_ACCOUNT_ERROR](state) {
    state.storePostfinanceAccountPending = false;
  },
  // FETCH_POSTFINANCE_DOCUMENTS
  [FETCH_POSTFINANCE_DOCUMENTS_PENDING](state) {
    state.fetchPostfinanceDocumentsPending = true;
  },
  [FETCH_POSTFINANCE_DOCUMENTS_FULFILLED](state) {
    state.fetchPostfinanceDocumentsPending = false;
  },
  [FETCH_POSTFINANCE_DOCUMENTS_ERROR](state) {
    state.fetchPostfinanceDocumentsPending = false;
  },
  // FETCH_POSTFINANCE_ACCOUNTS
  [FETCH_POSTFINANCE_ACCOUNTS_PENDING](state) {
    state.fetchPostfinanceAccountsPending = true;
  },
  [FETCH_POSTFINANCE_ACCOUNTS_FULFILLED](state, { data }) {
    state.postfinanceAccounts = data;
    state.fetchPostfinanceAccountsPending = false;
  },
  [FETCH_POSTFINANCE_ACCOUNTS_ERROR](state) {
    state.fetchPostfinanceAccountsPending = false;
  },
  // FETCH_ABABANK_STATEMENTS
  [FETCH_ABABANK_STATEMENTS_PENDING](state) {
    state.fetchAbabankStatementsPending = true;
  },
  [FETCH_ABABANK_STATEMENTS_FULFILLED](state) {
    state.fetchAbabankStatementsPending = false;
  },
  [FETCH_ABABANK_STATEMENTS_ERROR](state) {
    state.fetchAbabankStatementsPending = false;
  },
  // PUSH_ABABANK_STATEMENTS
  [PUSH_ABABANK_STATEMENTS_PENDING](state) {
    state.pushAbabankStatementsPending = true;
  },
  [PUSH_ABABANK_STATEMENTS_FULFILLED](state) {
    state.pushAbabankStatementsPending = false;
  },
  [PUSH_ABABANK_STATEMENTS_ERROR](state) {
    state.pushAbabankStatementsPending = false;
  },
  // SYNC_ABABANK
  [SYNC_ABABANK_PENDING](state, id) {
    state.syncAbabankPendingIds.push(id);
    state.syncAbabankPending = true;
  },
  [SYNC_ABABANK_FULFILLED](state, id) {
    state.syncAbabankPendingIds = state.syncAbabankPendingIds.filter((item) => item !== id);
    state.syncAbabankPending = false;
  },
  [SYNC_ABABANK_ERROR](state, id) {
    state.syncAbabankPendingIds = state.syncAbabankPendingIds.filter((item) => item !== id);
    state.syncAbabankPending = false;
  },
  // REMOVE_ABABANK
  [REMOVE_ABABANK_PENDING](state) {
    state.removeAbabankPending = true;
  },
  [REMOVE_ABABANK_FULFILLED](state) {
    state.removeAbabankPending = false;
  },
  [REMOVE_ABABANK_ERROR](state) {
    state.removeAbabankPending = false;
  },
  // REMOVE_ABABANK
  [CONNECT_ABABANK_PENDING](state) {
    state.connectAbabankPending = true;
  },
  [CONNECT_ABABANK_FULFILLED](state) {
    state.connectAbabankPending = false;
  },
  [CONNECT_ABABANK_ERROR](state) {
    state.connectAbabankPending = false;
  },
  [ONBOARDING_ABABANK_PENDING](state) {
    state.onboardingAbaBankPending = true;
  },
  [ONBOARDING_ABABANK_FULFILLED](state) {
    state.onboardingAbaBankPending = false;
  },
  [ONBOARDING_ABABANK_ERROR](state) {
    state.onboardingAbaBankPending = false;
  },
  [CALLBACK_ABABANK_PENDING](state) {
    state.callbackAbaBankPending = true;
  },
  [CALLBACK_ABABANK_FULFILLED](state) {
    state.callbackAbaBankPending = false;
  },
  [CALLBACK_ABABANK_ERROR](state) {
    state.callbackAbaBankPending = false;
  },
};

const actions = {
  async fetchAbabankBanks({ rootState, commit }) {
    commit(FETCH_ABABANK_BANKS_PENDING);
    try {
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/ababank/banks`);
      commit(FETCH_ABABANK_BANKS_FULFILLED, response);
      return true;
    } catch (error) {
      commit(FETCH_ABABANK_BANKS_ERROR, error);
      return Promise.reject(error);
    }
  },
  async storeAbabank({ rootState, commit, dispatch }) {
    commit(STORE_ABABANK_PENDING);
    try {
      const response = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/ababank`);
      commit(STORE_ABABANK_FULFILLED);
      if (response.data && response.data.redirect_url) {
        window.location.href = response.data.redirect_url;
      }
      return true;
    } catch (error) {
      dispatch('messages/showMessage', { text: error.data.message, color: 'error' }, { root: true });
      commit(STORE_ABABANK_ERROR, error);
      return Promise.reject(error);
    }
  },
  async storePostfinanceAccount({ rootState, commit, dispatch }, form) {
    commit(STORE_POSTFINANCE_ACCOUNT_PENDING);
    try {
      await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/ababank/accounts/postfinance`, form);
      commit(STORE_POSTFINANCE_ACCOUNT_FULFILLED);
      await dispatch('fetchPostfinanceAccounts');
      dispatch('messages/showMessage', { key: 'created_bank_account', color: 'success' }, { root: true });
      return true;
    } catch (error) {
      dispatch('messages/showMessage', { text: error.data.message, color: 'error' }, { root: true });
      commit(STORE_POSTFINANCE_ACCOUNT_ERROR, error);
      return Promise.reject(error);
    }
  },
  async fetchPostfinanceDocuments({ rootState, commit, dispatch }, { efinance_number: eFinanceNumber }) {
    commit(FETCH_POSTFINANCE_DOCUMENTS_PENDING);
    try {
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/ababank/postfinance/new-documents`, {
        responseType: 'blob',
        params: {
          efinance_number: eFinanceNumber,
        },
      });
      commit(FETCH_POSTFINANCE_DOCUMENTS_FULFILLED, response);
      const today = moment().format('YYYY-MM-DD');
      const filename = `postfinance-documents-${today}-qr.pdf`;
      const downloadLink = document.createElement('a');
      const url = URL.createObjectURL(response.data);
      downloadLink.style.display = 'none';
      downloadLink.href = url;
      downloadLink.setAttribute('download', filename);
      downloadLink.click();
      return true;
    } catch (error) {
      dispatch('messages/showMessage', {
        key: 'postfinance_document_generation_failed',
        color: 'error',
      }, { root: true });
      commit(FETCH_POSTFINANCE_DOCUMENTS_ERROR, error);
      return Promise.reject(error);
    }
  },
  async fetchPostfinanceAccounts({ rootState, commit, dispatch }) {
    commit(FETCH_POSTFINANCE_ACCOUNTS_PENDING);
    try {
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/ababank/accounts/postfinance`);
      commit(FETCH_POSTFINANCE_ACCOUNTS_FULFILLED, response);
      return true;
    } catch (error) {
      dispatch('messages/showMessage', { text: error.data.data.message, color: 'error' }, { root: true });
      commit(FETCH_POSTFINANCE_ACCOUNTS_ERROR, error);
      return Promise.reject(error);
    }
  },
  async fetchAbabankStatements({ rootState, commit, dispatch }, id) {
    commit(FETCH_ABABANK_STATEMENTS_PENDING);
    try {
      await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/ababank/statements/${id}`);
      commit(FETCH_ABABANK_STATEMENTS_FULFILLED);
      return true;
    } catch (error) {
      dispatch('messages/showMessage', { text: error.data.message, color: 'error' }, { root: true });
      commit(FETCH_ABABANK_STATEMENTS_ERROR);
      return Promise.reject(error);
    }
  },
  async pushAbabankPayments({ rootState, commit, dispatch }, id) {
    commit(PUSH_ABABANK_STATEMENTS_PENDING);
    try {
      await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/ababank/payments/${id}`);
      commit(PUSH_ABABANK_STATEMENTS_FULFILLED);
      return true;
    } catch (error) {
      dispatch('messages/showMessage', { text: error.data.message, color: 'error' }, { root: true });
      commit(PUSH_ABABANK_STATEMENTS_ERROR);
      return Promise.reject(error);
    }
  },
  async syncAbabank({ commit, dispatch }, id) {
    commit(SYNC_ABABANK_PENDING, id);
    try {
      await dispatch('fetchAbabankStatements', id);
      await dispatch('pushAbabankPayments', id);
      commit(SYNC_ABABANK_FULFILLED, id);
      return true;
    } catch (error) {
      commit(SYNC_ABABANK_ERROR, id);
      return Promise.reject(error);
    }
  },
  async removeAbaBank({ rootState, commit, dispatch }, id) {
    commit(REMOVE_ABABANK_PENDING, id);
    try {
      await axios.delete(`/accounts/${rootState.definitions.account.account.uuid}/ababank/accounts/${id}`);
      dispatch('messages/showMessage', { key: 'bank_connection_removed', color: 'success' }, { root: true });
      commit(REMOVE_ABABANK_FULFILLED);
      return Promise.resolve();
    } catch (error) {
      dispatch('messages/showMessage', { key: 'bank_connection_remove_failed', color: 'error' }, { root: true });
      commit(REMOVE_ABABANK_ERROR, id);
      return Promise.reject(error);
    }
  },
  async connectAbaBank({ commit, dispatch }, { ababankBank, id }) {
    commit(CONNECT_ABABANK_PENDING);
    if (ababankBank.is_redirect) {
      if (ababankBank.id !== 9000 || id === undefined) {
        window.location.replace(ababankBank.redirect_url);
      } else {
        window.location.replace(`${ababankBank.redirect_url}/${id}`);
      }
      return Promise.resolve(true);
    }
    try {
      const response = await axios.get(ababankBank.redirect_path);
      commit(CONNECT_ABABANK_FULFILLED);
      if (response && response.data && response.data.redirect_url) {
        window.location.href = response.data.redirect_url;
      }
      return Promise.resolve(response);
    } catch (error) {
      dispatch('messages/showMessage', { text: error.data.data.message, color: 'error' }, { root: true });
      commit(CONNECT_ABABANK_ERROR);
      return Promise.reject(error);
    }
  },

  async handleOnboarding({ commit, rootState }, { form }) {
    commit(ONBOARDING_ABABANK_PENDING);
    try {
      const params = { params: { ...form } };
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/ababank/onboarding`, params);
      commit(ONBOARDING_ABABANK_FULFILLED);
      return Promise.resolve(response);
    } catch (error) {
      commit(ONBOARDING_ABABANK_ERROR);
      return Promise.reject(error);
    }
  },

  async handleCallback({ commit, rootState }, { form }) {
    commit(CALLBACK_ABABANK_PENDING);
    try {
      const params = { params: { ...form } };
      const response = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/ababank/cb`, params);
      commit(CALLBACK_ABABANK_FULFILLED);
      return Promise.resolve(response);
    } catch (error) {
      commit(CALLBACK_ABABANK_ERROR);
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
};
