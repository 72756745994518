import type { PaginatedPayload } from '@/api/interfaces/payload';
import type { Tag } from '@/api/interfaces/tag';
import httpClient from '@/helpers/client/http-client';
import type { PaginationMetaResource } from '@/interfaces/pagination-meta-resource';
import type { AxiosPromise } from 'axios';

export function getAddressTags(
  meta: Partial<PaginationMetaResource>,
  abortController: AbortController = new AbortController(),
): AxiosPromise<PaginatedPayload<Array<Tag>>> {
  return httpClient.get<PaginatedPayload<Array<Tag>>>('/pagination/settings/tags/addresses', {
    params: {
      ...meta,
    },
    signal: abortController.signal,
  });
}

export function getBetaFeatures(
  abortController: AbortController = new AbortController(),
): AxiosPromise<PaginatedPayload<Array<Tag>>> {
  return getAddressTags({
    per_page: 'all',
    extend_search: {
      tag: '[',
    },
  }, abortController);
}

export function getProductTags(
  meta: Partial<PaginationMetaResource>,
  abortController: AbortController = new AbortController(),
): AxiosPromise<PaginatedPayload<Array<Tag>>> {
  return httpClient.get<PaginatedPayload<Array<Tag>>>('/pagination/settings/tags/products', {
    params: {
      ...meta,
    },
    signal: abortController.signal,
  });
}
