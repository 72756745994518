import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    initialLoading: true,
    initialError: false,
    fetchReceiptPending: false,
    importReceiptPending: false,
    storeReceiptPending: false,
    fetchAddressPending: false,
    signingUsers: [],
    addresses: [],
    duplicateAddresses: [
      { id: null },
    ],
    receipt: {},
    receiptQueue: [],
    address: {},
    projectAddress: {},
    storeActionPending: false,
    fetchProductRecognitionPending: false,
    batchEdit: {
      list: [],
    },
    fetchAccountingProposalsPending: false,
  },
  actions,
  mutations,
};
