import axios from 'axios';
import * as types from './types';

const actions = {
  async fetchInitial({ commit, dispatch }) {
    commit(types.FETCH_INITIAL_PENDING);
    try {
      const users = await dispatch('fetchSigningUsers');
      await dispatch('fetchImportQueues');
      commit(types.FETCH_SIGNING_USERS_FULFILLED, users);
      commit(types.FETCH_INITIAL_FULFILLED);
      return Promise.resolve(users);
    } catch (error) {
      commit(types.FETCH_INITIAL_ERROR);
      return Promise.reject(error);
    }
  },
  importReceipt({ commit }, data) {
    commit(types.IMPORT_RECEIPT_PENDING);
    try {
      commit(types.FETCH_DUPLICATE_ADDRESSES_FULFILLED, data.addresses);
      commit(types.IMPORT_RECEIPT_FULFILLED, data.invoice);
    } catch (error) {
      commit(types.IMPORT_RECEIPT_ERROR);
    }
  },
  async fetchProductRecognition({ rootState, commit }, fileUrl) {
    commit(types.FETCH_PRODUCT_RECOGNITION_PENDING);
    try {
      const data = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/import/invoices/product_recognition`, {
        pdf_url: fileUrl,
      });
      commit(types.FETCH_PRODUCT_RECOGNITION_FULFILLED);
      return Promise.resolve(data.data);
    } catch (error) {
      commit(types.FETCH_PRODUCT_RECOGNITION_ERROR);
      return Promise.reject(error);
    }
  },
  async fetchImportQueues({ rootState, commit }) {
    try {
      const data = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/receipt/imports/queue`);
      commit(types.FETCH_IMPORT_RECEIPT_QUEUE, data);
      return Promise.resolve(data.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async fetchImportQueue({ rootState }, id) {
    try {
      const data = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/receipt/imports/queue/${id}`);
      return Promise.resolve(data.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async deleteImportQueue({ rootState, dispatch }, id) {
    try {
      const data = await axios.delete(`/accounts/${rootState.definitions.account.account.uuid}/receipt/imports/${id}`);
      await dispatch('fetchImportQueues', { type: 'invoices' });
      return Promise.resolve(data.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async fetchAddress({ rootState, commit }, id) {
    commit(types.FETCH_ADDRESS_PENDING);
    try {
      commit(types.FETCH_ADDRESS_FULFILLED, await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/address/${id}`));
    } catch (error) {
      commit(types.FETCH_ADDRESS_ERROR, error);
    }
  },
  async fetchReceipt({ rootState, commit }, id) {
    commit(types.FETCH_RECEIPT_PENDING);
    try {
      const { data } = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/receipts/${id}`);
      if (data.address_id) {
        if (data.project_address_id) {
          const projectAddress = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/address/${data.project_address_id}`);
          commit(types.FETCH_PROJECT_ADDRESS_FULFILLED, projectAddress.data);
        }
        const address = await axios.get(`/accounts/${rootState.definitions.account.account.uuid}/address/${data.address_id}`);
        commit(types.FETCH_ADDRESS_FULFILLED, address);
        commit(types.FETCH_DUPLICATE_ADDRESSES_FULFILLED, [address.data]);
        commit(types.FETCH_RECEIPT_FULFILLED, data);
      } else {
        commit(types.FETCH_DUPLICATE_ADDRESSES_FULFILLED, data.addresses);
        commit(types.FETCH_RECEIPT_FULFILLED, data.invoice);
      }
    } catch (error) {
      commit(types.FETCH_RECEIPT_ERROR, error);
    }
  },
  async storeReceipt({ rootState, commit, dispatch }, data) {
    commit(types.STORE_RECEIPT_PENDING);
    try {
      const { id } = data;
      const method = id ? 'PATCH' : 'POST';
      const baseUrl = `/accounts/${rootState.definitions.account.account.uuid}/receipts`;
      const url = id ? `${baseUrl}/${id}` : baseUrl;
      const receipt = await axios({ url, method, data });
      commit(types.STORE_RECEIPT_FULFILLED, receipt);
      dispatch('messages/showMessage', { key: 'receipt_store_successful', color: 'success' }, { root: true });
      return Promise.resolve(receipt.data.invoice);
    } catch (error) {
      dispatch('messages/showMessage', { key: 'receipt_store_error', color: 'error' }, { root: true });
      commit(types.STORE_RECEIPT_ERROR, error);
      return Promise.reject(error);
    }
  },
  resetReceipt({ commit }) {
    commit(types.RESET_RECEIPT);
  },
  async storeAction({ rootState, commit, dispatch }, { params }) {
    commit(types.STORE_ACTION_PENDING);
    try {
      const response = await axios.post(`/accounts/${rootState.definitions.account.account.uuid}/invoices/receipt/action`, params);
      commit(types.STORE_ACTION_FULFILLED);
      dispatch('messages/showMessage', { text: response.message, color: 'success' }, { root: true });
      return Promise.resolve(response);
    } catch (error) {
      commit(types.STORE_ACTION_ERROR, error);
      // TODO: harmonize error message object key
      const message = error.data.data || error.data.message;
      dispatch('messages/showMessage', { text: message, color: 'error' }, { root: true });
      return Promise.reject(error);
    }
  },
  fileIsSending({ commit }) {
    commit(types.IMPORT_RECEIPT_PENDING);
  },
  async fetchSigningUsers({ rootState, commit }) {
    const accountId = rootState.definitions.account.account.uuid;
    const users = await axios.get(`/accounts/${accountId}/signoffusers`);
    commit(types.FETCH_SIGNING_USERS_FULFILLED, users);
    return users;
  },
  async fetchAccountingProposals({ commit }, addressUuid) {
    commit(types.FETCH_ACCOUNTING_PROPOSALS_PENDING);
    try {
      const response = await axios.get(`/api/deep-company-registry/v2/accounting-proposals/${addressUuid}`);
      commit(types.FETCH_ACCOUNTING_PROPOSALS_FULFILLED);
      return Promise.resolve(response.data);
    } catch (error) {
      commit(types.FETCH_ACCOUNTING_PROPOSALS_ERROR, error);
      return Promise.reject(error);
    }
  },
  fetchAddresses({ rootState }) {
    return axios.get(`/accounts/${rootState.definitions.account.account.uuid}/addresses/internal`);
  },
};

export default actions;
